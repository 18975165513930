import React, { useEffect } from "react";
import image1 from "../Assets/testimonial comments/001.JPG";
import image2 from "../Assets/testimonial comments/002.JPG";
import image3 from "../Assets/testimonial comments/003.JPG";
import image4 from "../Assets/testimonial comments/004.JPG";
import image5 from "../Assets/testimonial comments/005.JPG";
import image6 from "../Assets/testimonial comments/006.JPG";
import image7 from "../Assets/testimonial comments/007.JPG";
import image8 from "../Assets/testimonial comments/008.JPG";
import image9 from "../Assets/testimonial comments/009.JPG";
import image10 from "../Assets/testimonial comments/010.JPG";
import image11 from "../Assets/testimonial comments/011.JPG";
import image12 from "../Assets/testimonial comments/012.JPG";
import image13 from "../Assets/testimonial comments/013.JPG";
import OwlCarousels from "react-owl-carousel";
import AOS from "aos";
import "aos/dist/aos.css";

const Testimonials = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="container">
      <h1
        className="section-heading"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        Testimonials
      </h1>
      <p className="para-body" data-aos="fade-right" data-aos-duration="1000">
        The 21DC Community has had a tremendous impact on the lives of its
        members. Since its inception, we have helped over 14,000 people improve
        their skills, achieve their goals, and live their best lives. Our
        courses, resources, and support have been instrumental in helping our
        members reach their full potential and make positive changes in their
        lives. We are proud of the difference we have made in the lives of so
        many people, and we are committed to continuing to provide high-quality
        resources and support to help even more people succeed.We offer a
        variety of programs to help you achieve your goals and improve your
        skills in various areas of your life.
      </p>
      <p className="para-body" data-aos="fade-left" data-aos-duration="1000">
        We are constantly inspired by the stories of success and transformation
        from our members. We believe that everyone has the potential to make
        positive changes in their lives, and we are dedicated to providing the
        resources and support needed to help our members succeed. Please take a
        moment to read the testimonials below to see how the 21DC Community has
        made a difference in the lives of others.
      </p>
      <div className="container d-none d-md-block">
        <div className="contentalignment">
          <div className="row justify-content-center">
            <div
              className="col-4"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image1} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-4"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image2} className="testmonialimg" alt="..." />
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-4"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image3} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-4"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image4} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-4 contentalign2"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image5} className="testmonialimg" alt="..." />
            </div>
          </div>
          <div className="row justify-content-end">
            <div
              className="col-3"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image6} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-5"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image7} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-4"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image8} className="testmonialimg" alt="..." />
            </div>
          </div>
          <div className="row justify-content-around">
            <div
              className="col-4 contentalign"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image9} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-3"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image10} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-5 contentalign"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image11} className="testmonialimg" alt="..." />
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-3 contentalign1"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={image12} className="testmonialimg" alt="..." />
            </div>
            <div
              className="col-3 contentalign1"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img src={image13} className="testmonialimg" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="container testi-res-slider d-md-none d-block">
          <div className="row justify-content-center">
          <OwlCarousels
              className="owl-theme slider-product"
              loop
              autoplay
              items="3"
              dots={false}
              smartSpeed={1500}
              nav={false}
              margin={10}
              center={true}
              responsive={{
                360: {
                  items: "1",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "3",
                },
              }}
            >
            <div className="" >
              <img src={image1} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image2} className="testi-res-img" alt="..." />
            </div>
            <div className="" >
              <img src={image3} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image4} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image5} className="testi-res-img" alt="..." />
            </div>
            <div className="" >
              <img src={image6} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image7} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image8} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image9} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image10} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image11} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image12} className="testi-res-img" alt="..." />
            </div>
            <div className="">
              <img src={image13} className="testi-res-img" alt="..." />
            </div>
          </OwlCarousels>
          </div>
      </div>
      
    </section>
  );
};

export default Testimonials;
