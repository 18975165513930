import React, { useEffect } from "react";
import bg from "../Assets/Png/bg-img2.jpg";
import star from "../Assets/reviewwhatsapp/star.png";
import reviewwhatsapp1 from "../Assets/reviewwhatsapp/1.png";
import reviewwhatsapp2 from "../Assets/reviewwhatsapp/2.png";
import reviewwhatsapp3 from "../Assets/reviewwhatsapp/3.png";
import reviewwhatsapp4 from "../Assets/reviewwhatsapp/4.png";
import reviewwhatsapp5 from "../Assets/reviewwhatsapp/5.png";
import reviewwhatsapp6 from "../Assets/reviewwhatsapp/6.png";
import reviewwhatsapp7 from "../Assets/reviewwhatsapp/7.png";
import reviewwhatsapp8 from "../Assets/reviewwhatsapp/8.png";
import reviewwhatsapp9 from "../Assets/reviewwhatsapp/9.png";
import reviewwhatsapp10 from "../Assets/reviewwhatsapp/10.png";
import reviewwhatsapp11 from "../Assets/reviewwhatsapp/11.png";
import reviewwhatsapp12 from "../Assets/reviewwhatsapp/12.png";
import whatsapp1 from "../Assets/reviewimage/1.png";
import whatsapp2 from "../Assets/reviewimage/2.png";
import whatsapp3 from "../Assets/reviewimage/3.png";
import whatsapp4 from "../Assets/reviewimage/4.png";
import whatsapp5 from "../Assets/reviewimage/5.png";
import whatsapp6 from "../Assets/reviewimage/6.png";
import whatsapp7 from "../Assets/reviewimage/7.png";
import whatsapp8 from "../Assets/reviewimage/8.png";
import whatsapp9 from "../Assets/reviewimage/9.png";
import whatsapp10 from "../Assets/reviewimage/10.png";
import whatsapp11 from "../Assets/reviewimage/11.png";
import whatsapp12 from "../Assets/reviewimage/12.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Remarkable = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="TUAF-section mt-5">
        <div className="container">
          <h1 className="section-heading">Remarkable Breakthroughs</h1>
          <div className="row">
            <div className="col-lg-12  my-auto  col-md-12">
              <div className="about-content">
                <p
                  className="para-body"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  The 5 am webinars offered by the 21 DC Community have
                  consistently produced remarkable breakthroughs for our
                  participants. From overcoming personal challenges and
                  achieving long-held goals to discovering new passions and
                  making positive changes in their lives, our webinars have
                  provided a platform for people to make meaningful progress and
                  experience extraordinary results.
                </p>
                <p
                  className="para-body"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  The support and guidance Dharani, the camaraderie and
                  accountability of our online community, or the
                  transformational power of the water droplet magic, the content
                  and exercises we offer, our webinars have helped countless
                  people achieve breakthroughs they never thought possible. We
                  are proud to be a part of this process and to see the
                  incredible impact our webinars have had on so many lives.
                </p>
              </div>
              <div className="bgcontainer">
                <img className="bgimage d-lg-none d-block" src={bg} alt="" />
                <div className="bgcontent d-none d-lg-block">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          data-aos="zoom-in-up"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp1}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal1"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp1}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-3"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                          data-aos="zoom-in-left"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp2}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal2"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp2}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal3"
                          data-aos="zoom-in-right"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp3}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal3"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp3}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal4"
                          data-aos="zoom-in-right"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp4}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal4"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp4}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal5"
                          data-aos="zoom-out"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp5}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal5"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp5}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal6"
                          data-aos="zoom-in-left"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp6}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal6"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp6}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal7"
                          data-aos="zoom-in-down"
                          data-aos-duration="1000"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp7}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal7"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp7}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal8"
                          data-aos="zoom-in-up"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp8}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal8"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp8}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal9"
                          data-aos="zoom-in"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp9}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal9"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp9}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal10"
                          data-aos="zoom-in-right"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp10}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal10"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp10}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal11"
                          data-aos="zoom-in-down"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp11}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal11"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp11}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-3">
                        <div
                          className="reviewrectangle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal12"
                          data-aos="zoom-in-right"
                          data-aos-duration="1500"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <img
                                className="reviewwhatsappimg"
                                src={reviewwhatsapp12}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h6 className="reviewtitle">
                                Review of Our Clients
                              </h6>
                              <img className="starimg" src={star} alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal12"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <img
                                  className="whatsappimg"
                                  src={whatsapp12}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bgcontentpop d-block d-lg-none">
                  <button
                    type="button"
                    className="btn  btn-white btn-light px-3 py-2"
                    data-bs-toggle="modal"
                    data-bs-target="#responsivemodel"
                  >
                    <span>View My Reviews</span>
                  </button>
                  <div className="popdotlarge"></div>
                  <div className="popdotsmall"></div>
                  <div
                    className="modal fade"
                    id="responsivemodel"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp1}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp2}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp3}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp4}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp5}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp6}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp7}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp8}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp9}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp10}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp11}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="reviewrectangle">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img
                                      className="reviewwhatsappimg"
                                      src={reviewwhatsapp12}
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-lg-8">
                                    <h6 className="reviewtitle">
                                      Review of Our Clients
                                    </h6>
                                    <img
                                      className="starimg"
                                      src={star}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="about-content1">
                <p
                  className="para-body"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  Join us on our mission to make a positive impact in the world
                  and help others live their best lives. If you have any
                  questions or would like more information about our courses,
                  please don't hesitate to contact us. We are here to help you
                  succeed!
                </p>
                <p
                  className="para-body"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  Join the 21dccommunity and embrace the possibilities as we
                  take action towards a holistic and abundant lifestyle."
                  "Embrace the possibilities and take action with us!"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remarkable;
