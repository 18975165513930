import React, { useEffect } from "react";
import AImg1 from "../Assets/Png/about-img.png";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="about-section pt-5" id="AboutUs">
      <div className="container pt-3">
        <h1
          className="section-heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          About Us
        </h1>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="about-img">
              <img className="a-img" src={AImg1} alt="" />
            </div>
          </div>
          <div
            className="col-lg-6 my-auto col-md-6"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="about-content">
              <p className="para-heading">Welcome to the 21DC Community!</p>
              <p className="para-body">
                The 21DC Community was founded by Dharaneetharan, who has a
                passion for helping others improve their lives and achieve their
                full potential.With his expertise and experience, Dharaneetharan
                has curated the best actionable blueprints to help people take
                control of their lives and make positive changes.
              </p>
              <p className="para-body">
                These blueprints are designed to be easy to follow and
                implement, so that anyone can act on them effortlessly and see
                real results. We are dedicated to improving ourselves and
                helping others to do the same. We provide high-quality resources
                and support to help people reach their full potential and live
                their best lives.
              </p>
              <p className="para-body">
                The mission of the 21DC Community is to help 1 million people
                live an abundant and holistic lifestyle.Through the provision of
                high-quality programs, partnerships and collaborations with
                other organisations and individuals, utilisation of social media
                and other online platforms, and a focus on sustainability and
                scalability, the 21DC Community is dedicated to making a
                positive impact in the world and helping people improve their
                lives and reach their full potential.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
