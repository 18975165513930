import React from "react";
import { Link } from "react-router-dom";
import footerlogo from "../Assets/Png/footerlogo.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="footerlogo">
              <Link to="/">
                <img className="w-100 h-auto" src={footerlogo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="footeralign">
              <div className="row">
                {/* <div className="col-lg-2 col-md-3 d-lg-block d-md-none col-sm-6 col-12 d-sm-block">
                  <h6 className="footertitle">Community</h6>
                  <ul className="footercontentalign">
                    <li className="footercontent">Blog</li>
                    <li className="footercontent">Forum</li>
                  </ul>
                </div> */}
                <div className="col-lg-3 col-md-3 d-lg-block  d-md-none col-sm-6 col-12 d-sm-block">
                  {/* <h6 className="footertitle">Support</h6>
                  <ul className="footercontentalign">
                    <li className="footercontent">FAQs</li>
                    <li className="footercontent">Privacy Policy</li>
                    <li className="footercontent">Terms of Us</li>
                    <li className="footercontent">Refund Policy</li>
                  </ul> */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-8">
                  <h6 className="footertitle">Explore</h6>
                  <ul className="footercontentalign">
                    <li>
                      <Link className="nav-item nav-link footercontent" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-title nav-link footercontent"
                        to="#AboutUs"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-title nav-link footercontent"
                        to="#21DCCommunity"
                      >
                        21 DC Community
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-title nav-link footercontent"
                        to="#UltraAction"
                      >
                        The Ultra Action Framework
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-item nav-link footercontent"
                        to="/Digitalmarketing"
                      >
                        IDMM
                      </Link>
                    </li>
                    <li>
                      <a
                        className="nav-item nav-link footercontent"
                        target="_blank"
                        href="http://shop.21dc.community/"
                      >
                        Shop@21dc
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-item nav-link footercontent"
                        target="_blank"
                        href="https://dharaneetharan.teachable.com/"
                      >
                        Courses
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-item nav-link footercontent"
                        target="_blank"
                        href="http://onestop.21dc.community/"
                      >
                        Community
                      </a>
                    </li>
                    {/* <li className="footercontent">Get in Touch With Us</li> */}
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 p-lg-0 p-md-0">
                  <h6 className="footertitle">Contact</h6>
                  <ul className="footercontentalign">
                    <li>
                      <span className="footercontent2">Email : </span>
                      <a
                        className="footercontentemail"
                        href="mailto:gdd@socialeagle.in"
                      >
                        gdd@socialeagle.in
                      </a>
                    </li>

                    <li>
                      <span className="footercontent2">Phone No : </span>
                      <a className="footercontent" href="tel:+919361468236">
                        9361468236
                      </a>
                    </li>
                  </ul>
                  <ul className="footersocial">
                    <li>
                      <a
                        className="socialmedia-links"
                        target="_blank"
                        href="http://facebook.com/dharaneetharangd/reviews"
                      >
                        <i class="fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="socialmedia-links"
                        target="_blank"
                        href="http://instagram.com/dharaneetharan/"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="socialmedia-links"
                        target="_blank"
                        href="https://www.youtube.com/digitallifestylemonk"
                      >
                        <i class="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
