import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Css/Style.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Digitalmarketing from "./Components/Digitalmarketing";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Digitalmarketing" element={<Digitalmarketing />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
