import React, { useEffect, useState } from "react";
import bannerlogo from "../Assets/Png/bannerlogo.svg";
import AboutUs from "./AboutUs";
import Community from "./Community";
import ProgramStreams from "./ProgramStreams";
import Framework from "./Framework";
import Retreat from "./Retreat";
import Remarkable from "./Remarkable";
import { useLocation } from "react-router-dom";
import Testimonials from "./Testimonials";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import Typewriter from "typewriter-effect";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <Header />
          <section className="banner">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 p-0"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div className="typewriting">
                    <h2>21 DC Community</h2>&nbsp;
                    <Typewriter
                      options={{
                        strings: [
                          "is Growth",
                          "is Standing For Each Other",
                          "is Lifelong Learning",
                          "is Possibility Thinking",
                          "is Alliance",
                          "is Clan",
                          "is Proffesional Association",
                          "is Culture",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </div>

                  {/* <div class="container py-5">
                    <div class="output" id="output">
                      <h1 class="cursor bannertext">21 DC Community</h1>
                      <p></p>
                    </div>
                  </div> */}
                  {/* <div class="typewriter">
                    <p className="bannertext">21 DC Community is GROWTH</p>
                    <p className="bannertext-list">- Standing for each other </p>
                    <p className="bannertext-list">- Growth </p>
                    <p className="bannertext-list">- Lifelong Learning </p>
                    <p className="bannertext-list">- Possibility Thinking </p>
                    <p className="bannertext-list">- Alliance </p>
                    <p className="bannertext-list">- Clan </p>
                    <p className="bannertext-list">- Proffesional association </p>
                    <p className="bannertext-list">- Culture </p>
                  </div> */}
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 bannerlogo"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img
                    className="bannerimage"
                    src={bannerlogo}
                    alt="bannerlogo"
                  />
                </div>
              </div>
            </div>
          </section>
          <AboutUs />
          <Community />
          <Testimonials />
          <ProgramStreams />
          <Framework />
          <Retreat />
          <Remarkable />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;
