import React, { useEffect } from "react";
import HImg1 from "../Assets/himalayan/img10.png";
import HImg2 from "../Assets/himalayan/img18.png";
import HImg3 from "../Assets/himalayan/img11.png";
import HImg4 from "../Assets/himalayan/img16.png";
import HImg5 from "../Assets/himalayan/img13.png";
import HImg6 from "../Assets/himalayan/img17.png";
import HImg7 from "../Assets/himalayan/img15.png";
import HImg8 from "../Assets/himalayan/img12.png";
import HImg9 from "../Assets/himalayan/img14.png";
import image1 from "../Assets/himalayan/1.png";
import image2 from "../Assets/himalayan/2.png";
import image3 from "../Assets/himalayan/3.png";
import image4 from "../Assets/himalayan/4.png";
import image5 from "../Assets/himalayan/5.png";
import image6 from "../Assets/himalayan/6.png";
import image7 from "../Assets/himalayan/7.png";
import image8 from "../Assets/himalayan/8.png";
import image9 from "../Assets/himalayan/9.png";
import image10 from "../Assets/himalayan/10.png";
import OwlCarousels from "react-owl-carousel";
import AOS from "aos";
import "aos/dist/aos.css";
const urlText = "https://forms.gle/zoCyBQzoC49n3L497";

const Retreat = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div >
      <div className="pt-5" id="Retreat">
        <div className="TUAF-section pt-3">
          <div className="container">
            <h1
              className="section-heading"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              The Himalayan Retreat
            </h1>
            <div className="row">
              <div className="col-lg-6  my-auto col-md-6">
                <div className="about-content">
                  <p
                    className="para-body"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    The Himalayan Retreat is a unique and transformative
                    experience designed to help participants discover their inner
                    selves and achieve higher energy levels. This 10-day program
                    offers a rare opportunity to escape the distractions of daily
                    life and fully immerse oneself in a journey of self-discovery
                    and spiritual growth.
                  </p>
                  <p
                    className="para-body"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    The Himalayan Retreat takes place in the beautiful and serene
                    surroundings of the Himalayas, surrounded by mountains and at
                    the oak of the river Gangas. Participants have the opportunity
                    to visit a variety of sacred and awe-inspiring locations,
                    including Badrinath, Rishikesh, and Haridwar. One of the
                    highlights of the program is a day of complete silence, during
                    which participants can rediscover themselves and shed their
                    egos in the peaceful presence of nature.
                  </p>
                  <p
                    className="para-body"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    In addition to exploring the spiritual and natural beauty of
                    the Himalayas, the program also includes visits to Mana, the
                    last village of India, and Auli, the paradise on earth.
                    Throughout the journey, participants have the opportunity to
                    learn from each other and connect with others on a deeper
                    level.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div
                  className="about-img"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img className="w-100 h-auto" src={HImg1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BTP-section pt-5">
          <div className="container">
            <p
              className="para-heading text-center"
              data-aos="fade-top"
              data-aos-duration="1000"
            >
              Benefits of the Program
            </p>
            <p
              className="para-body"
              data-aos="fade-bottom"
              data-aos-duration="2000"
            >
              The Himalayan Retreat is a once-in-a-lifetime opportunity to
              recharge and revitalize both body and mind. Surrounded by the
              majesty of the Himalayas and the peaceful energy of nature,
              participants can tap into a higher source of energy and tap into
              their full potential. By shedding their ego and embracing the
              present moment, participants can discover new insights about
              themselves and their place in the world.
            </p>
            <div className="row py-3">
              <div className="col-lg-6 col-md-6">
                <div
                  className="about-img"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <img className="w-100 h-auto br-img" src={HImg2} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-img">
                  <div className="row ">
                    <div className="col-lg-12 mt-img mb-5">
                      <div
                        className="img-fit-height"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <img className="w-100 h-auto br-img" src={HImg3} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div
                        className="img-fit-height"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <img className="w-100 h-auto br-img" src={HImg4} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-6"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <div className="img-fit-height">
                        <img
                          className="w-100 h-auto  br-img"
                          src={HImg5}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BTP-section pt-5">
          <div className="container">
            <p
              className="para-heading text-center"
              data-aos="fade-top"
              data-aos-duration="2000"
            >
              Program Location
            </p>
            <p
              className="para-body"
              data-aos="fade-bottom"
              data-aos-duration="2000"
            >
              The Himalayan Retreat takes place in one of the most beautiful and
              spiritual locations on earth, the Himalayas. From the majestic peaks
              to the holy rivers and sacred temples, the Himalayas offer a rich
              tapestry of cultural and natural beauty. By escaping the
              distractions of daily life and immersing oneself in this stunning
              and sacred landscape, participants can connect with their inner
              selves and achieve a greater sense of peace and clarity.
            </p>
            <div className="row py-3">
              <div className="col-lg-6 col-md-6">
                <div className="about-img">
                  <div className="row">
                    <div className="col-lg-12 mb-5">
                      <div
                        className="img-fit-height"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <img className="w-100 h-auto br-img" src={HImg6} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div
                        className="img-fit-height"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <img className="w-100 h-auto br-img" src={HImg7} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div
                        className="img-fit-height"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <img className="w-100 h-auto br-img" src={HImg8} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div
                  className="about-img mt-img"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <img className="w-100 h-auto br-img" src={HImg9} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BTP-section pb-5">
          <div className="container">
            <p className="para-heading text-center">A Glimpse into the Past</p>
          </div>
          <section className="container">
            <div>
              <OwlCarousels
                className="owl-theme slider-product"
                loop
                autoplay
                items="3"
                dots={false}
                smartSpeed={1000}
                nav={false}
                margin={20}
                center={true}
                responsive={{
                  360: {
                    items: "1",
                  },
                  992: {
                    items: "3",
                  },
                  1200: {
                    items: "3",
                  },
                }}
              >
                <img src={image1} className="d-blocks" alt="..." />
                <img src={image2} className="d-blocks" alt="..." />
                <img src={image3} className="d-blocks" alt="..." />
                <img src={image4} className="d-blocks" alt="..." />
                <img src={image5} className="d-blocks" alt="..." />
                <img src={image6} className="d-blocks" alt="..." />
                <img src={image7} className="d-blocks" alt="..." />
                <img src={image8} className="d-blocks" alt="..." />
                <img src={image9} className="d-blocks" alt="..." />
                <img src={image10} className="d-blocks" alt="..." />
              </OwlCarousels>
            </div>
          </section>
        </div>
        <div className="bgcolor-section py-5">
          <div className="container">
            <p
              className="para-body text-center"
              data-aos="fade-bottom"
              data-aos-duration="2000"
            >
              The Himalayan Retreat is a truly unique and transformative
              experience that offers the opportunity to discover one's inner self
              and achieve higher energy levels. Whether you're seeking spiritual
              growth, personal development, or simply a chance to escape the
              distractions of daily life, the Himalayan Retreat is the perfect
              program for you. We invite you to join us on this once-in-a-lifetime
              journey of self-discovery and spiritual growth.Don't miss out on
              this rare opportunity to discover your inner self and achieve higher
              energy levels. Register now for the Himalayan Retreat and embark on
              a journey of self-discovery and spiritual growth. We can't wait to
              welcome you to the Himalayas and help you connect with your inner
              self and tap into your full potential.
            </p>
            <div className="text-center">
              <button
                type="button"
                className="btn  btn-white btn-light px-3 py-2"
                onClick={() => {
                  window.open(urlText, "_blank");
                }}
                data-aos="fade-top"
                data-aos-duration="2000"
              >
                <span>Join Now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retreat;
