import React, { Fragment, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ProgramStreams() {
  useEffect(() => {
    AOS.init();
  }, []);
  const urlLinks = {
    basic: "https://imjo.in/bVYnBU",
    professional: "https://imjo.in/ed85EG",
    premium: "https://imjo.in/BrFesU",
    free: "http://onestop.21dc.community",
    ultraPremium: "https://forms.gle/hZjKiQxTMQgKzWYQA",
  };
  const [active, setActive] = useState("4");

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  return (
    <div className="container">
      <div className="BTP-section">
        <div>
          <h1
            className="section-heading"
            data-aos="fade-bottom"
            data-aos-duration="2000"
          >
            Program Streams
          </h1>
          <div className="program-head-content py-3">
            <Fragment>
              <div className="" data-aos="fade-left" data-aos-duration="2000">
                <div
                  className={
                    active === "1" ? "active program-card" : "program-card"
                  }
                  id={"1"}
                  onClick={handleClick}
                >
                  <button
                    type="button"
                    className="btn  btn-orange btn-light px-3 py-2"
                  >
                    <span>Free</span>
                  </button>
                  <p className="para-body1">Access to Basic Portal</p>
                  <hr />
                  <p className="para-heading text-center my-3">FREE</p>
                  <hr />
                  <p className="para-body ">
                    <span className="material-symbols-rounded orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to the Basic Community Portal
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to Members Directory
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to important resources
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Portal Chat Functionality
                  </p>
                  <div className="text-center program-fixed-btn mt-auto">
                    <button
                      type="button"
                      className="btn  btn-orange btn-light px-3 py-2"
                      onClick={() => {
                        window.open(urlLinks.free, "_blank");
                      }}
                    >
                      <span>Free</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="" data-aos="fade-left" data-aos-duration="2000">
                <div
                  className={
                    active === "2" ? "active program-card" : "program-card"
                  }
                  key={2}
                  id={"2"}
                  onClick={handleClick}
                >
                  <button
                    type="button"
                    className="btn  btn-orange btn-light px-3 py-2"
                  >
                    <span>Basic</span>
                  </button>
                  <p className="para-body1">Access to 5 Day Course</p>
                  <hr />
                  <p className="para-heading text-center my-3">
                    ₹ 555 Incl.GST
                  </p>
                  <hr />
                  <p className="para-body ">
                    <span className="material-symbols-rounded orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to the Basic Community Portal
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to Members Directory
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to important resources
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Portal Chat Functionality
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to The 5 days course (Cohort Basis)
                  </p>
                  <div className="text-center program-fixed-btn mt-auto">
                    <button
                      type="button"
                      className="btn  btn-orange btn-light px-3 py-2"
                      onClick={() => {
                        window.open(urlLinks.basic, "_blank");
                      }}
                    >
                      <span>Purchase</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <div
                  className={
                    active === "3" ? "active program-card" : "program-card"
                  }
                  key={3}
                  id={"3"}
                  onClick={handleClick}
                >
                  <button
                    type="button"
                    className="btn  btn-orange btn-light px-3 py-2"
                  >
                    <span>Professional </span>
                  </button>
                  <p className="para-body1">
                    Annual Subscription + 50 Live Meetings (Weekly)
                  </p>
                  <hr />
                  <p className="para-heading text-center my-3">
                    ₹ 9999 +18% GST
                  </p>
                  <hr />
                  <p className="para-body ">
                    <span className="material-symbols-rounded orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to the Basic Community Portal
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to Members Directory
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to important resources
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Portal Chat Functionality
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to The 5 days course
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to business-Ask & Give (1 Year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to market (promote your products) (1 year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <div className="text-center program-fixed-btn mt-auto">
                    <button
                      type="button"
                      className="btn  btn-orange btn-light px-3 py-2"
                      onClick={() => {
                        window.open(urlLinks.professional, "_blank");
                      }}
                    >
                      <span>Purchase </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="" data-aos="fade-right" data-aos-duration="2000">
                <div
                  className={
                    active === "4" ? "active program-card" : "program-card"
                  }
                  key={4}
                  id={"4"}
                  onClick={handleClick}
                >
                  <button
                    type="button"
                    className="btn  btn-orange btn-light px-3 py-2"
                  >
                    <span>Premium</span>
                  </button>
                  <p className="para-body1">
                    Annual Subscription + 50+ Hours of Courses + 50 Live Meeting
                    (Weekly)
                  </p>
                  <hr />
                  <p className="para-heading text-center my-3">
                    ₹ 19,999 +18% GST
                  </p>
                  <hr />
                  <p className="para-body ">
                    <span className="material-symbols-rounded orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to the Basic Community Portal
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to Members Directory
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to important resources
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Portal Chat Functionality
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to The 5 days course
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to business-Ask & Give (1 Year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to market (promote your products) (1 year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Course access (Life time)
                  </p>
                  <ul>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      21 Day Challenge
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Facebook Blueprint and Automation
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      LinkedIn Mastery
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      LinkedIn Organic
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Strategy
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Instagram Blueprint
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Email Marketing
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Video Making
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Ancient Hidden Secrets
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      You & Your Relationship
                    </li>
                  </ul>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Privilege Access For Offline Event Booking
                  </p>

                  <div className="text-center program-fixed-btn mt-auto">
                    <button
                      type="button"
                      className="btn  btn-orange btn-light px-3 py-2"
                      onClick={() => {
                        window.open(urlLinks.premium, "_blank");
                      }}
                    >
                      <span>Purchase</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="" data-aos="fade-right" data-aos-duration="2000">
                <div
                  className={
                    active === "5" ? "active program-card" : "program-card"
                  }
                  key={5}
                  id={"5"}
                  onClick={handleClick}
                >
                  <button
                    type="button"
                    className="btn  btn-orange btn-light px-3 py-2"
                  >
                    <span>Ultra Premium </span>
                  </button>
                  <p className="para-body1">
                    Lifetime Subscription + 50+ Hours of Courses + 50 Live
                    Meeting (Weekly)
                  </p>
                  <hr />
                  <p className="para-heading text-center my-3">
                    ₹ 1,99,999 +18% GST
                  </p>
                  <hr />
                  <p className="para-body ">
                    <span className="material-symbols-rounded orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to the Basic Community Portal
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to Members Directory
                  </p>
                  <p className="para-body">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to important resources
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Portal Chat Functionality
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to The 5 days course
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to business-Ask & Give (1 Year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to market (promote your products) (1 year)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Meetings (Weekly Meetings)
                  </p>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Access to 50 Live Course access (Life time)
                  </p>
                  <ul>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      21 Day Challenge
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Facebook Blueprint and Automation
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      LinkedIn Mastery
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      LinkedIn Organic
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Strategy
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Instagram Blueprint
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Email Marketing
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Video Making
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      Ancient Hidden Secrets
                    </li>
                    <li>
                      <span className="material-symbols-rounded  green-bg align-middle filled-icon">
                        check_box
                      </span>
                      You & Your Relationship
                    </li>
                  </ul>
                  <p className="para-body ">
                    <span className="material-symbols-rounded  orange-bg align-middle filled-icon">
                      check_circle
                    </span>
                    Privilege Access For Offline Event Booking
                  </p>
                  <div className="text-center program-fixed-btn mt-auto">
                    <button
                      type="button"
                      className="btn  btn-orange btn-light px-3 py-2"
                      onClick={() => {
                        window.open(urlLinks.ultraPremium, "_blank");
                      }}
                    >
                      <span>Join Waitlist</span>
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
}
