import React, { useEffect } from "react";
import AImg1 from "../Assets/himalayan/img9.png";
import BTPImg1 from "../Assets/himalayan/img7.png";
import BTPImg2 from "../Assets/himalayan/img5.png";
import BTPImg3 from "../Assets/himalayan/img1.png";
import BTPImg4 from "../Assets/himalayan/img6.png";
import BTPImg11 from "../Assets/himalayan/img3.png";
import BTPImg12 from "../Assets/himalayan/img2.png";
import BTPImg13 from "../Assets/himalayan/img8.png";
import BTPImg14 from "../Assets/himalayan/img4.png";
import image1 from "../Assets/Ultra-action/1.jpg";
import image2 from "../Assets/Ultra-action/2.jpg";
import image3 from "../Assets/Ultra-action/3.jpg";
import image4 from "../Assets/Ultra-action/4.jpg";
import image5 from "../Assets/Ultra-action/15.jpg";
import image6 from "../Assets/Ultra-action/6.jpg";
import image7 from "../Assets/Ultra-action/7.jpg";
import image8 from "../Assets/Ultra-action/8.jpg";
import image9 from "../Assets/Ultra-action/9.jpg";
import image10 from "../Assets/Ultra-action/10.jpg";
import OwlCarousels from "react-owl-carousel";
import AOS from "aos";
import "aos/dist/aos.css";

const urlText = "https://forms.gle/DcK2QzFVDHagv6536";

const Framework = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="TUAF-section pt-5 mt-5" id="UltraAction">
        <div className="container">
          <h1
            className="section-heading1"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            The Ultra Action Framework
          </h1>
          <div className="row">
            <div className="col-lg-6  my-auto  col-md-6">
              <div className="about-content">
                <p
                  className="para-body"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  The 21DC Community is a supportive and empowering network of
                  individuals dedicated to helping people live an abundant and
                  holistic lifestyle. Our founder, Dharaneetharan, has developed
                  a powerful 3-day program called the Ultra Action Framework,
                  designed to help people find their purpose, set goals, and
                  overcome limiting beliefs.
                </p>
                <p
                  className="para-body"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  The Ultra Action Framework is a residential program that takes
                  place in a beautiful location close to nature. Throughout the
                  program, participants have the opportunity to engage in a
                  variety of activities that help them discover their values,
                  create a vision for their lives, and develop strategies for
                  success. These activities include water therapy, the water
                  droplet challenge, and the bucket list exercise.
                </p>
                <p
                  className="para-body"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  In addition to these experiential activities, the program also
                  includes sessions on removing limiting beliefs, decluttering,
                  and understanding one's own personality. Participants have the
                  chance to create vision boards and practice affirmations, as
                  well as learn about the factors that contribute to success.
                  The program also includes a focus on the 4 circles of life:
                  personal development, relationships, career, and health and
                  wellness.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="about-img"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img className="w-100 h-auto" src={AImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BTP-section py-5">
        <div className="container">
          <p
            className="para-heading text-center"
            data-aos="fade-top"
            data-aos-duration="1000"
          >
            Benefits of the Program
          </p>
          <p
            className="para-body"
            data-aos="fade-bottom"
            data-aos-duration="1000"
          >
            The benefits of participating in a residential program like the
            Ultra Action Framework are numerous. By disconnecting from daily
            distractions and fully immersing oneself in the learning process,
            participants can gain clarity about their goals and make positive
            changes in their lives. The program provides a supportive and
            nurturing environment that encourages growth and self-discovery.
          </p>
          <div className="row py-3">
            <div className="col-lg-6 col-md-6">
              <div
                className="about-img"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img className="w-100 h-auto br-img" src={BTPImg1} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-img">
                <div className="row ">
                  <div className="col-lg-12 mt-img mb-5">
                    <div
                      className="img-fit-height"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        className=" w-100 h-auto br-img"
                        src={BTPImg2}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <div
                      className="img-fit-height"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img
                        className=" w-100 h-auto br-img"
                        src={BTPImg3}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6  col-6">
                    <div
                      className="img-fit-height"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        className="  w-100 h-auto br-img"
                        src={BTPImg4}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BTP-section pb-5">
        <div className="container">
          <p
            className="para-heading text-center"
            data-aos="fade-top"
            data-aos-duration="1000"
          >
            Program Location
          </p>
          <p
            className="para-body"
            data-aos="fade-bottom"
            data-aos-duration="1000"
          >
            The Ultra Action Framework program takes place in a peaceful and
            serene location close to nature. Being surrounded by natural beauty
            and fresh air has been shown to have a positive impact on physical
            and mental health, making it the perfect setting for a program
            focused on personal growth and wellness.
          </p>
          <div className="row py-3">
            <div className="col-lg-6 col-md-6">
              <div className="about-img">
                <div className="row">
                  <div className="col-lg-12 mb-5">
                    <div
                      className="img-fit-height mt-img"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        className="w-100 h-auto br-img"
                        src={BTPImg11}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <div
                      className="img-fit-height"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img
                        className="w-100 h-auto br-img"
                        src={BTPImg12}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <div
                      className="img-fit-height"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        className="w-100 h-auto br-img"
                        src={BTPImg13}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="about-img mt-img"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img className="w-100 h-auto br-img" src={BTPImg14} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BTP-section pb-5">
        <div className="container">
          <p className="para-heading text-center">A Retrospective Collection</p>
        </div>
        <section className="container">
          <div>
            <OwlCarousels
              className="owl-theme slider-product"
              loop
              autoplay
              items="3"
              dots={false}
              smartSpeed={1000}
              nav={false}
              margin={20}
              center={true}
              responsive={{
                360: {
                  items: "1",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "3",
                },
              }}
            >
              <img src={image1} className="d-blocks" alt="..." />
              <img src={image2} className="d-blocks" alt="..." />
              <img src={image3} className="d-blocks" alt="..." />
              <img src={image4} className="d-blocks" alt="..." />
              <img src={image5} className="d-blocks" alt="..." />
              <img src={image6} className="d-blocks" alt="..." />
              <img src={image7} className="d-blocks" alt="..." />
              <img src={image8} className="d-blocks" alt="..." />
              <img src={image9} className="d-blocks" alt="..." />
              <img src={image10} className="d-blocks" alt="..." />
            </OwlCarousels>
          </div>
        </section>
      </div>
      <div className="bgcolor-section py-5">
        <div className="container">
          <p
            className="para-body text-center"
            data-aos="fade-bottom"
            data-aos-duration="2000"
          >
            The 21DC Community is dedicated to helping people live their best
            lives and achieve their full potential. The Ultra Action Framework
            program is a powerful and transformative experience that can help
            participants find their purpose, set goals, and overcome limiting
            beliefs. Don't wait any longer to take control of your life and
            start achieving your goals. Register now for the Ultra Action
            Framework program and join us on a journey of personal growth and
            transformation. We can't wait to welcome you to the Ultra Action
            Framework Residential Program and help you live an abundant and
            holistic lifestyle.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn  btn-white btn-light px-3 py-2"
              onClick={() => {
                window.open(urlText, "_blank");
              }}
              data-aos="fade-top"
              data-aos-duration="3500"
            >
              <span>Join Now</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Framework;
