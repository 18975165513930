import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Community = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="pt-3" id="21DCCommunity">
      <div className="WDC-section-head py-5">
        <div className="container">
          <h1
            className="section-heading"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            What Defines 21 DC Community?
          </h1>
        </div>
        <div className="WDC-section ">
          <div className="container">
            <div className="row justify-content-center py-5">
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">
                      Social connections
                    </p>
                    <p className="para-body2 mb-0">
                      Our community is built on social connections and
                      interactions between members, providing a sense of
                      belonging and support.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">
                      Collaboration
                    </p>
                    <p className="para-body2 mb-0">
                      Our community values collaboration and cooperation between
                      members, helping us to achieve our common goals and create
                      a sense of teamwork.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">
                      Shared values or beliefs
                    </p>
                    <p className="para-body2 mb-0">
                      The 21DC Community is brought together by our shared
                      commitment to personal growth and self-improvement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">Diversity</p>
                    <p className="para-body2 mb-0">
                      The 21DC Community is diverse in terms of our membership,
                      bringing a range of perspectives and experiences to the
                      group.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">
                      A sense of belonging
                    </p>
                    <p className="para-body2 mb-0">
                      Our members feel a strong sense of belonging and
                      connection to the 21DC Community, fostering a sense of
                      community spirit and pride.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-top"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">
                      Inclusivity
                    </p>
                    <p className="para-body2 mb-0">
                      The 21DC Community is inclusive, welcoming people from all
                      backgrounds and walks of life.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="dc-community-content mx-auto my-2"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <div>
                    <p className="para-heading2 text-center mb-0">Support </p>
                    <p className="para-body2 mb-0">
                      The 21DC Community is committed to providing support and
                      encouragement to our members, helping them to achieve
                      their goals and feel supported in their endeavours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
