import React, { Fragment, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import dmimg from "../Assets/DM/D-m banner-img.png";
import Group19091 from "../Assets/DM/Group19091.png";
import timer from "../Assets/DM/timer.svg";
import pratical from "../Assets/DM/pratical.svg";
import updateversion from "../Assets/DM/updateversion.svg";
import participence from "../Assets/DM/participence.svg";
import coursekitbook from "../Assets/DM/coursekitbook.png";
import expectgained from "../Assets/DM/expectgained.png";
import rightarrow from "../Assets/DM/rightarrow.png";
import importantpts from "../Assets/DM/importantpts.png";
// import other1 from "../Assets/DM/other-1.png";
// import other2 from "../Assets/DM/other-2.png";
// import other3 from "../Assets/DM/other-3.png";
// import other4 from "../Assets/DM/other-4.png";
// import other5 from "../Assets/DM/other-5.png";
// import other6 from "../Assets/DM/other-6.png";
import other1 from "../Assets/Digitalmarketing/nine.jpeg";
import other2 from "../Assets/Digitalmarketing/ten.jpeg";
import other3 from "../Assets/Digitalmarketing/twelve.jpeg";
import other4 from "../Assets/Digitalmarketing/three.jpeg";
import other5 from "../Assets/Digitalmarketing/seven.jpeg";
import other6 from "../Assets/Digitalmarketing/five.jpeg";

import other7 from "../Assets/Digitalmarketing/five.jpeg";
import other8 from "../Assets/Digitalmarketing/thirteen.jpeg";
import other9 from "../Assets/Digitalmarketing/one.jpeg";
import other10 from "../Assets/Digitalmarketing/two.jpeg";

const Digitalmarketing = () => {
  const [hideDay1, setHideDay1] = useState(true);
  const [hideDay2, setHideDay2] = useState(false);
  const [hideDay3, setHideDay3] = useState(false);
  const [active, setActive] = useState(1);

  const showDay1 = (event) => {
    setHideDay1(true);
    setHideDay2(false);
    setHideDay3(false);
    setActive(1);
  };
  const showDay2 = (event) => {
    setHideDay1(false);
    setHideDay2(true);
    setHideDay3(false);
    setActive(2);
  };
  const showDay3 = (event) => {
    setHideDay1(false);
    setHideDay2(false);
    setHideDay3(true);
    setActive(3);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const urlLinks = {
    villa: "https://imjo.in/sjUsXr",
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="DigitalmarketingHead">
            <Header />
            <div className="Digitalmarketing">
              <div className="mastermind-banner">
                <div className="container">
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-sm-12"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className="sections-headings">
                        First Of Its Kind In
                        <br />
                        <span className="section-heading">Tamil</span>
                      </h1>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img src={dmimg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="welcome-section">
                <div className="container">
                  <h3
                    className="section-heading"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    Welcome to the Intense Digital Marketing Mastermind
                  </h3>
                  <p
                    className="para-body"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    Are you ready to take your digital marketing skills to the
                    next level? Join us at the luxurious private villa in
                    <strong className="strongtext">ECR, Chennai</strong> on
                    February 24-26 for a transformative learning experience that
                    will give you the knowledge and confidence you need to
                    succeed in the world of consumer business. This event is
                    designed for entrepreneurs, solopreneurs, software
                    engineers, digital marketers, students, and anyone else who
                    is interested in growing their business through digital
                    marketing. Whether you're just starting out or you're
                    looking to level up your skills, the Intense Digital
                    Marketing Mastermind has something to offer you. The
                    learning at this event will be intense, but it will also be
                    enjoyable. As a result, you will find that the learning
                    happens automatically.
                  </p>
                </div>
              </div>
              <div className="trinerandteam">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h1
                        className="section-heading"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        About the trainer & team
                      </h1>
                      <p
                        className="para-body"
                        data-aos="zoom-in-right"
                        data-aos-duration="1000"
                      >
                        Dharaneetharan is a Digital Lifestyle Monk, the founder
                        of Social Eagle Pvt Ltd, a digital marketing agency
                        based in Chennai, and the curator of the world's first 5
                        AM webinar. He has served over 200 clients, including
                        big names like Nestle and Haagen Daz, and worked as Head
                        of Marketing for Naturals, India's No.1 Salon Chain.
                        Dharaneetharan has also impacted the lives of over 1.5
                        lakh people through his motivational talks and
                        industry-oriented seminars, and has received numerous
                        accolades for his work, including the award for Best JC
                        of the Year from Junior Chamber International.
                      </p>
                      <p
                        className="para-body"
                        data-aos="zoom-in-left"
                        data-aos-duration="1000"
                      >
                        He holds a bachelor's degree in Computer Science
                        Engineering from the Madras Institute of Technology and
                        has worked as a software engineer for several US-based
                        telecom companies. He is a popular personality in TV and
                        newsprint and is known for giving interviews on topics
                        such as digital marketing, entrepreneurship, and
                        self-development. Dharaneetharan is driven by the belief
                        in possibility thinking and is on a mission to
                        positively impact one million Tamil people by teaching
                        them life tools, internet, and technology to live an
                        abundant lifestyle
                      </p>
                      <p
                        className="para-body"
                        data-aos="zoom-in-right"
                        data-aos-duration="1000"
                      >
                        Dharaneetharan, a highly experienced and respected
                        figure in the world of digital marketing, will be
                        leading the Intense Digital Marketing Mastermind program
                        along with his team. This program is designed to be 100%
                        practical, providing attendees with the skills and
                        knowledge they need to succeed in the world of consumer
                        business. His team is equally experienced and committed
                        to providing a valuable and transformative learning
                        experience. Participants can expect to leave the program
                        with a deep understanding of digital marketing best
                        practices and the skills and confidence they need to
                        succeed in the consumer business world.
                      </p>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <img src={Group19091} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="live-session-section">
                <div className="container">
                  <div className="row">
                    <div
                      className="col-lg-3 col-md-3 col-sm-12"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div className="live-session-1">
                        <img src={timer} alt="" />
                        <h2>
                          32+ Hours
                          <p>Live Sessions</p>
                        </h2>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-12"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <div className="live-session-1">
                        <img src={pratical} alt="" />
                        <h2>
                          100%
                          <p>Practical </p>
                        </h2>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-12"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div className="live-session-1">
                        <img src={updateversion} alt="" />
                        <h2>
                          2023
                          <p>Updated Version</p>
                        </h2>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-12"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <div className="live-session-1">
                        <img src={participence} alt="" />
                        <h2>
                          12
                          <p>Participants Only</p>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Day-learn">
                <div className="container">
                  <h2
                    className="section-heading"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    What will you learn
                  </h2>
                  <p
                    className="para-body"
                    data-aos="zoom-in-left"
                    data-aos-duration="1000"
                  >
                    Here's what you can expect to learn during each day of the
                    event:
                  </p>
                  <div className="row">
                    <div className="container">
                      <div className="active event-head-circle steps">
                        <Fragment>
                          <ul className="bg-color">
                            <li
                              onClick={showDay1}
                              id={1}
                              className={`${
                                active >= 1 ? "active" : "inactive"
                              } col-4 event-1 `}
                            >
                              <h2>Day 01</h2>
                            </li>
                            <li
                              onClick={showDay2}
                              id={2}
                              className={`${
                                active >= 2 ? "active" : "inactive"
                              } col-4 event-1`}
                            >
                              <h2>Day 02</h2>
                            </li>
                            <li
                              onClick={showDay3}
                              id={3}
                              className={`${
                                active >= 3 ? "active" : "inactive"
                              } col-4 event-1`}
                            >
                              <h2>Day 03</h2>
                            </li>
                          </ul>
                        </Fragment>
                      </div>
                    </div>
                    <div className="row event-page">
                      {hideDay1 && (
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 day-event-page"
                          data-aos="flip-up"
                          data-aos-duration="1000"
                        >
                          <h6>Current Trends:</h6>
                          <p className="para-body">
                            Stay up-to-date with the latest developments in the
                            digital marketing industry and learn about the
                            strategies and tactics that are currently driving
                            results for businesses like yours.
                          </p>
                          <h6>Setting Clear Objectives and Goals:</h6>
                          <p className="para-body">
                            Understand the importance of setting specific,
                            measurable, achievable, relevant, and time-bound
                            (SMART) goals for your business, and use our
                            objective-finding sheets to identify the objectives
                            that will drive your digital marketing efforts.
                          </p>
                          <h6>
                            Choosing the Right Platforms and Finding Your
                            Audience:
                          </h6>
                          <p className="para-body">
                            Learn how to select the platforms that are most
                            likely to reach your target audience, define your
                            customer avatar using our customer avatar sheets,
                            and understand the role that content plays in
                            attracting and retaining customers.
                          </p>
                          <h6>Creating Actionable Designs and Content:</h6>
                          <p className="para-body">
                            Use Canva to create professional-quality designs
                            that will help you stand out online, develop a
                            content calendar that will guide your content
                            creation efforts, and learn how to create compelling
                            content that will engage and persuade your audience.
                          </p>
                          <h6>Making Videos:</h6>
                          <p className="para-body">
                            Discover the power of video marketing and learn how
                            to create videos that will capture your audience's
                            attention, understand the key elements of a
                            successful video and how to incorporate them into
                            your own videos.
                          </p>
                        </div>
                      )}
                      {hideDay2 && (
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 day-event-page"
                          data-aos="flip-up"
                          data-aos-duration="1000"
                        >
                          <h6>Understanding Meta Ad Managers and Pixels:</h6>
                          <p className="para-body">
                            Learn how to use meta ad managers and pixels to
                            track and optimize your ads, avoid common
                            pitfalls(ad rejections) and make sure your ads are
                            approved and delivered to the right audience.
                          </p>
                          <h6>Setting Clear Objectives and Goals:</h6>
                          <p className="para-body">
                            Learn the ins and outs of launching your own ads,
                            from purchasing your own domain and hosting to
                            creating landing pages and setting up retargeting
                            campaigns.
                          </p>
                        </div>
                      )}
                      {hideDay3 && (
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 day-event-page"
                          data-aos="flip-up"
                          data-aos-duration="1000"
                        >
                          <h6>Automation Mastery:</h6>
                          <p className="para-body">
                            Explore three tools that will help you automate
                            various aspects of your digital marketing efforts,
                            learn how to use these tools to save time and
                            increase efficiency.
                          </p>
                          <h6>Dedicated Q&A Sessions:</h6>
                          <p className="para-body">
                            Get personalized support and clarification on any
                            topics covered during the event.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="numbers-speaks">
                <div className="container">
                  <h2 className="section-heading">Our Numbers Speak for us</h2>
                  <div className="number-speak-card">
                    <div
                      className="numbers-speaks-cards card-1"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <h3>18+ Years</h3>
                      <p>Experience</p>
                    </div>
                    <div
                      className="numbers-speaks-cards card-2"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <h3>$1.7 M+</h3>
                      <p>Spends</p>
                    </div>
                    <div
                      className="numbers-speaks-cards card-3"
                      data-aos="flip-up"
                      data-aos-duration="1000"
                    >
                      <h3>200+</h3>
                      <p>Clients</p>
                    </div>
                    <div
                      className="numbers-speaks-cards card-4"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <h3>700K+</h3>
                      <p>Leads</p>
                    </div>
                    <div
                      className="numbers-speaks-cards card-5"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <h3>$6 M+</h3>
                      <p>Revenue Generated</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-kit">
                <div className="container-fluid ">
                  <div className="container">
                    <h2 className="section-heading">Course Kit</h2>
                  </div>
                  <div className="rowed">
                    <div className="container">
                      <div className="row ">
                        <div
                          className="col-lg-3 col-md-6 col-sm-12"
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          <img src={coursekitbook} alt="" />
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12">
                          <div className="row">
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-up"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card">
                                <p>Best Performing Email Templates</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card">
                                <p>
                                  Pillars of FB Ad Compliance
                                  <br />
                                  (Steps to avoid Account bans)
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-1">
                                <p>Budget Calculator</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card">
                                <p>5W Avatar</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-up"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-2">
                                <p>1-Min video Template</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card">
                                <p>Copy writing Frameworks</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-2a">
                                <p>Naming convension Framework</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6 d-block d-md-none"
                              data-aos="fade-down-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4a ">
                                <p>Offer Creation Templates</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-3">
                                <p className="para-body">
                                  Structure to create an AD creative
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-3">
                                <p>Social Media Calender</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-3a">
                                <p>Content Planning template</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-3">
                                <p>15 Landing page audit</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="zoom-in-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4a">
                                <p>Business Objective Template</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4">
                                <p>Marketing Objective Template</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4a">
                                <p>Unheard audience list for Retargeting</p>
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6"
                              data-aos="fade-down-right"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4">
                                <p>Headline Swipe Files</p>
                              </div>
                            </div>
                            <div className="col-lg-2 d-none d-md-block"></div>
                            <div
                              className="col-lg-2 col-md-6 col-sm-6 col-6 d-none d-md-block"
                              data-aos="fade-down-left"
                              data-aos-duration="1000"
                            >
                              <div className="circle-card circle-4a ">
                                <p>Offer Creation Templates</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bonus">
                <div className="container">
                  <h2 data-aos="zoom-in-left" data-aos-duration="1000">
                    Bonus: Additional 2-Hour Doubt-Clearing Session
                  </h2>
                  <p data-aos="zoom-in-right" data-aos-duration="1000">
                    Join a live Zoom session after the event to get additional
                    <br /> help with any questions or doubts you may have
                  </p>
                </div>
              </div>
              <div className="expect-gained">
                <div className="container">
                  <h2
                    className="section-heading"
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  >
                    You can expect to have gained
                  </h2>
                  <div className="row">
                    <div
                      className="col-lg-7 col-md-6 col-sm-12"
                      data-aos="zoom-in-right"
                      data-aos-duration="1000"
                    >
                      <img src={expectgained} alt="" />
                    </div>
                    <div
                      className="col-lg-5 col-md-6 col-sm-12"
                      data-aos="zoom-in-left"
                      data-aos-duration="1000"
                    >
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          A deep understanding of digital marketing best
                          practices and strategies.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          The skills and confidence you need to succeed in the
                          consumer business world.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          A supportive community of like-minded individuals who
                          can provide guidance and inspiration as you continue
                          on your journey.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          A beautiful experience that you'll always remember
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="expect-gained important-points">
                <div className="container">
                  <h2
                    className="section-heading"
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  >
                    Important points to note before Registering
                  </h2>
                  <div className="row">
                    <div
                      className="col-lg-5 col-md-6 col-sm-12"
                      data-aos="zoom-in-left"
                      data-aos-duration="1000"
                    >
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          Please only register for this event if you are serious
                          about implementing what you learn.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          The sessions on the first day may run late into the
                          night, so please plan accordingly.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          Bring a good laptop with reasonable speed, as you will
                          be doing your own creatives during the event.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          Have your company logos and product images in .png
                          format and either bring them on a pen drive or store
                          them online.
                        </span>
                      </p>
                      <p className="para-body">
                        <img className="right-arrow" src={rightarrow} alt="" />
                        <span>
                          If you plan on implementing ads through a landing
                          page, you will need to purchase a domain name and
                          space. Please have your payment methods ready for this
                          purpose.
                        </span>
                      </p>
                    </div>
                    <div
                      className="col-lg-7 col-md-6 col-sm-12"
                      data-aos="zoom-in-right"
                      data-aos-duration="1000"
                    >
                      <img src={importantpts} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="miss-out">
                <div className="container">
                  <p data-aos="flip-down" data-aos-duration="1000">
                    Date: Feb 24, 25 & 26 - 2023 &ensp; &ensp;&ensp;Venue:
                    Private Villa, ECR, Chennai
                  </p>
                  <h3 data-aos="flip-up" data-aos-duration="1000">
                    Don't miss out on this opportunity to take your digital
                    marketing skills to the next level. Register now to reserve.
                  </h3>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-white btn-light px-3 py-2 aos-init aos-animate"
                      data-aos="fade-top"
                      data-aos-duration="2000"
                      onClick={() => {
                        window.open(urlLinks.villa, "_blank");
                      }}
                    >
                      <span>Register Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="others-photos">
                <div className="BTP-section pb-5">
                  <section className="container">
                    <h2
                      className="section-heading"
                      data-aos="flip-down"
                      data-aos-duration="1000"
                    >
                      Experience the Same Excitement of College Learning with
                      Great Content and Super Fun.
                    </h2>
                    <div>
                    <div className="row">
                        <div
                          className="col-lg-6 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other6}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other4}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row py-5">
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other2}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other1}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other5}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other3}
                            alt=""
                          />
                        </div>
                      </div>
                      

                      <div className="row py-5" style={{marginTop:'-100px'}}>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other7}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other8}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other9}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other10}
                            alt=""
                          />
                        </div>
                      </div>
                      {/* <div className="row">
                        <div
                          className="col-lg-6 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other6}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other4}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row py-5">
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other2}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other1}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other5}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-lg-3 col-12"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100 h-auto br-img py-3"
                            src={other3}
                            alt=""
                          />
                        </div>
                      </div> */}
                    </div>
                  </section>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Digitalmarketing;
