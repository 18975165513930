import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/Png/logo.png";

const Header = () => {
  return (
    <section className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="row w-100">
            <div className="col-lg-3 col-md-11 col-sm-12 row">
              <div className="col-6 navbarfix">
                <Link to="/">
                  <img className="logo" src={logo} alt="logo" />
                </Link>
              </div>
              <div className="col-2 p-0 d-flex align-items: center">
                <button
                  className="navbar-toggler shadow-none ms-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>

            <div className="col-lg-9 col-md-7 col-sm-12 p-0 my-auto">
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo02"
              >
                <div className="navbar-nav mx-auto headerlinkspace">
                  <Link className="nav-item nav-link headerlinktitle" to="/">
                    Home
                  </Link>
                  <Link
                    className="nav-item nav-title nav-link headerlinktitle "
                    to="#AboutUs"
                  >
                    About Us
                  </Link>
                  <Link
                    className="nav-item nav-title nav-link headerlinktitle "
                    to="#21DCCommunity"
                  >
                    21 DC Community
                  </Link>
                  <Link
                    className="nav-item nav-title nav-link headerlinktitle "
                    to="#UltraAction"
                  >
                    The UAF
                  </Link>
                  <Link
                    className="nav-item nav-title nav-link headerlinktitle "
                    to="#Retreat"
                  >
                    The Himalayan Retreat
                  </Link>
                  <Link
                    className="nav-item nav-link headerlinktitle"
                    to="/Digitalmarketing"
                  >
                    IDMM
                  </Link>
                  <a
                    className="nav-item nav-link headerlinktitle"
                    target="_blank"
                    href="https://dharaneetharan.teachable.com/"
                  >
                    Courses
                  </a>
                  <a
                    className="nav-item nav-link headerlinktitle"
                    target="_blank"
                    href="http://onestop.21dc.community/"
                  >
                    Community
                  </a>
                  <a
                    className="nav-item nav-link headerlinktitle"
                    target="_blank"
                    href="http://shop.21dc.community/"
                  >
                    Shop@21DC
                  </a>
                </div>
                <div className="hamburger">
                  <div className="hamburger_btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
